export const UTM = {
  source: 'utm_source',
  medium: 'utm_medium',
  campaign: 'utm_campaign',
} as const

export const utmKeys = [UTM.source, UTM.medium, UTM.campaign] as const
export type Utms = Partial<Record<(typeof utmKeys)[number], string>> | null

export const parseUtms = (searchParams: URLSearchParams) => {
  const [utm_source, utm_medium, utm_campaign] = utmKeys.map(
    utm => searchParams.get(utm) ?? undefined,
  )

  return { utm_source, utm_medium, utm_campaign }
}
